var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"main-content content bgc-grey-200"},[_c('div',{staticClass:"p-20 col-md-12 bgc-grey-200",staticStyle:{"z-index":"1100"}},[_vm._m(0),_c('div',{staticClass:"masonry-item"},[_c('div',{staticClass:"bgc-white p-20 bd card col-md-8"},[_c('form',[_c('div',{staticClass:"form-group"},[_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.register_data.company_kanji),expression:"register_data.company_kanji"}],ref:"company_kanji",class:'form-control h-75' +
                (_vm.errors && _vm.errors.company_kanji && _vm.errors.company_kanji.length > 0 ? ' is-invalid' : ''),attrs:{"type":"name","disabled":_vm.disabled_company_kanji,"id":"company_kanji","placeholder":"企業名","data-e2e":"register_company_kanji"},domProps:{"value":(_vm.register_data.company_kanji)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.register_data, "company_kanji", $event.target.value)}}}),(_vm.errors && _vm.errors.company_kanji)?_c('div',{staticClass:"text-danger"},_vm._l((_vm.errors.company_kanji),function(msg,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(msg)+" ")])}),0):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"company_kana"}},[_vm._v("企業名(カナ)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.register_data.company_kana),expression:"register_data.company_kana"}],ref:"company_kana",class:'form-control h-75' +
                (_vm.errors && _vm.errors.company_kana && _vm.errors.company_kana.length > 0 ? ' is-invalid' : ''),attrs:{"type":"name","disabled":_vm.disabled_company_kana,"id":"company_kana","placeholder":"キギョウメイ","data-e2e":"register_company_kana"},domProps:{"value":(_vm.register_data.company_kana)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.register_data, "company_kana", $event.target.value)}}}),(_vm.errors && _vm.errors.company_kana)?_c('div',{staticClass:"text-danger"},_vm._l((_vm.errors.company_kana),function(msg,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(msg)+" ")])}),0):_vm._e()]),_c('div',{staticClass:"form-group"},[_vm._m(2),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.register_data.name),expression:"register_data.name"}],ref:"name",class:'form-control h-75' + (_vm.errors && _vm.errors.name && _vm.errors.name.length > 0 ? ' is-invalid' : ''),attrs:{"type":"name","disabled":_vm.disabled_name,"id":"name","placeholder":"山田 太郎","data-e2e":"register_name"},domProps:{"value":(_vm.register_data.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.register_data, "name", $event.target.value)}}}),(_vm.errors && _vm.errors.name)?_c('div',{staticClass:"text-danger"},_vm._l((_vm.errors.name),function(msg,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(msg)+" ")])}),0):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"department_kanji"}},[_vm._v("部署名(漢字)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.register_data.department_kanji),expression:"register_data.department_kanji"}],ref:"department_kanji",class:'form-control h-75' +
                (_vm.errors && _vm.errors.department_kanji && _vm.errors.department_kanji.length > 0 ? ' is-invalid' : ''),attrs:{"type":"name","disabled":_vm.disabled_department_kanji,"id":"department_kanji","placeholder":"部署名","data-e2e":"register_department_kanji"},domProps:{"value":(_vm.register_data.department_kanji)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.register_data, "department_kanji", $event.target.value)}}}),(_vm.errors && _vm.errors.department_kanji)?_c('div',{staticClass:"text-danger"},_vm._l((_vm.errors.department_kanji),function(msg,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(msg)+" ")])}),0):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"department_kana"}},[_vm._v("部署名(カナ)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.register_data.department_kana),expression:"register_data.department_kana"}],ref:"department_kana",class:'form-control h-75' +
                (_vm.errors && _vm.errors.department_kana && _vm.errors.department_kana.length > 0 ? ' is-invalid' : ''),attrs:{"type":"name","disabled":_vm.disabled_department_kana,"id":"department_kana","placeholder":"ブショメイ","data-e2e":"register_department_kana"},domProps:{"value":(_vm.register_data.department_kana)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.register_data, "department_kana", $event.target.value)}}}),(_vm.errors && _vm.errors.department_kana)?_c('div',{staticClass:"text-danger"},_vm._l((_vm.errors.department_kana),function(msg,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(msg)+" ")])}),0):_vm._e()]),_c('div',{staticClass:"form-group"},[_vm._m(3),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.register_data.email),expression:"register_data.email"}],class:'form-control h-75',attrs:{"type":"email","disabled":"","id":"email","placeholder":"sample@example.com","data-e2e":"register_email"},domProps:{"value":(_vm.register_data.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.register_data, "email", $event.target.value)}}})]),_c('div',{staticClass:"form-group"},[_vm._m(4),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.register_data.phone_number),expression:"register_data.phone_number"}],ref:"phone_number",class:'form-control h-75' +
                (_vm.errors && _vm.errors.phone_number && _vm.errors.phone_number.length > 0 ? ' is-invalid' : ''),attrs:{"type":"name","disabled":_vm.disabled_phone_number,"id":"phone_number","placeholder":"000-0000-0000","data-e2e":"register_phone_number"},domProps:{"value":(_vm.register_data.phone_number)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.register_data, "phone_number", $event.target.value)}}}),(_vm.errors && _vm.errors.phone_number)?_c('div',{staticClass:"text-danger"},_vm._l((_vm.errors.phone_number),function(msg,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(msg)+" ")])}),0):_vm._e()]),_c('div',{staticClass:"form-group"},[_vm._m(5),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.register_data.password),expression:"register_data.password"}],class:'form-control h-75' + (_vm.errors && _vm.errors.password && _vm.errors.password.length > 0 ? ' is-invalid' : ''),attrs:{"type":"password","id":"password","placeholder":"","data-e2e":"register_password"},domProps:{"value":(_vm.register_data.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.register_data, "password", $event.target.value)}}}),(_vm.errors && _vm.errors.password)?_c('div',{staticClass:"text-danger"},_vm._l((_vm.errors.password),function(msg,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(msg)+" ")])}),0):_vm._e()]),_c('div',{staticClass:"form-group"},[_vm._m(6),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.register_data.password_confirmation),expression:"register_data.password_confirmation"}],class:'form-control h-75' +
                (_vm.errors && _vm.errors.password_confirmation && _vm.errors.password_confirmation.length > 0
                  ? ' is-invalid'
                  : ''),attrs:{"type":"password","id":"password_confirmation","placeholder":"","data-e2e":"register_password_confirmation"},domProps:{"value":(_vm.register_data.password_confirmation)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.register_data, "password_confirmation", $event.target.value)}}}),(_vm.errors && _vm.errors.password_confirmation)?_c('div',{staticClass:"text-danger"},_vm._l((_vm.errors.password_confirmation),function(msg,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(msg)+" ")])}),0):_vm._e()]),_c('PasswordPolicy',{attrs:{"passwordPolicyMin":this.passwordPolicyMin,"passwordPolicyMax":this.passwordPolicyMax}}),_c('div',{staticClass:"d-flex justify-content-center"},[(!_vm.isLoading)?_c('button',{staticClass:"btn btn-info btn-sm",attrs:{"type":"button","data-e2e":"register_submit"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" 登録する ")]):_vm._e(),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"btn btn-info btn-sm",attrs:{"type":"button","disabled":""}},[_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}}),_vm._v(" 登録中… ")])])],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{staticClass:"c-grey-900 mt-3"},[_vm._v("企業・ユーザー登録")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"company_kanji"}},[_vm._v("企業名(漢字)"),_c('span',{staticClass:"badge badge-danger ml-1 py-1"},[_vm._v("必須")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_vm._v("名前"),_c('span',{staticClass:"badge badge-danger ml-1 py-1"},[_vm._v("必須")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"email"}},[_vm._v("メールアドレス"),_c('span',{staticClass:"badge badge-danger ml-1 py-1"},[_vm._v("必須")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"phone_number"}},[_vm._v("電話番号"),_c('span',{staticClass:"badge badge-danger ml-1 py-1"},[_vm._v("必須")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"password"}},[_vm._v("パスワード"),_c('span',{staticClass:"badge badge-danger ml-1 py-1"},[_vm._v("必須")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"password_confirmation"}},[_vm._v("パスワード再入力"),_c('span',{staticClass:"badge badge-danger ml-1 py-1"},[_vm._v("必須")])])
}]

export { render, staticRenderFns }