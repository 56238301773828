<!-- oktacicに登録済みか未登録かによって表示切り分け用のvueファイルのため、ここでは画面表示は行わず、RegisterWithPw.vueまたはRegisterWithoutPw.vueで表示を実施
画面表示は無いが削除するとエラーになるため空の<template>を置く -->
<template>
  <div></div>
</template>

<script>
import { joinApiMixin } from '@/mixins/join/join_api.js';

export default {
  mixins: [joinApiMixin],
  data() {
    return {
      isLoading: false,
      idaas_is_user_exist: null,
    };
  },
  created() {
    this.execute_api();
  },
  methods: {
    async execute_api() {
      this.isLoading = true;
      this.idaas_is_user_exist = (await this.getApiData(`idaas_is_user_exist`)) || { result: false };
      this.isLoading = false;

      if (this.idaas_is_user_exist.result === true) {
        this.$router.push({ path: '/join/register_without_pw' });
      } else {
        this.$router.push({ path: '/join/register_with_pw' });
      }
    },
  },
};
</script>