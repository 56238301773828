<template>
  <main class="main-content content bgc-grey-200">
    {{ this.$route.query.Technical }}
    <div>
      <h4 class="c-grey-900 mt-3" data-e2e="page_title_name">お問い合わせフォーム</h4>
    </div>
    <div class="masonry-item">
      <div class="bgc-white p-20 bd card col-md-8">
        <form>
          <!-- お問い合わせサービス -->
          <div class="form-group" v-if="isTechnical">
            <label for="application_id"
              >お問い合わせサービス <span class="badge badge-danger ml-1 py-1">必須</span></label
            >
            <div class="col-sm-10">
              <div class="form-check mb-1" v-if="isCanario">
                <label class="form-check-label">
                  <input
                    id="application_id9"
                    :class="'form-check-input h-75' + (errors && errors.application_id ? ' is-invalid ' : '')"
                    type="radio"
                    name="application_id"
                    :value="9"
                    v-model="user.application_id"
                    data-e2e="contactform_application_id9"
                  />
                  Canario
                </label>
              </div>
              <div class="form-check mb-1" v-if="isOWLai">
                <label class="form-check-label">
                  <input
                    id="application_id10"
                    :class="'form-check-input h-75' + (errors && errors.application_id ? ' is-invalid ' : '')"
                    type="radio"
                    name="application_id"
                    :value="10"
                    v-model="user.application_id"
                    data-e2e="contactform_application_id10"
                  />
                  OWLai
                </label>
              </div>
              <div class="form-check mb-1" v-if="isRDLGWAN">
                <label class="form-check-label">
                  <input
                    id="application_id14"
                    :class="'form-check-input h-75' + (errors && errors.application_id ? ' is-invalid ' : '')"
                    type="radio"
                    name="application_id"
                    :value="14"
                    v-model="user.application_id"
                    data-e2e="contactform_application_id14"
                  />
                  リモートデスクトップ for LGWAN
                </label>
              </div>
              <div class="form-check mb-1" v-if="isBoxLGWAN">
                <label class="form-check-label">
                  <input
                    id="application_id15"
                    :class="'form-check-input h-75' + (errors && errors.application_id ? ' is-invalid ' : '')"
                    type="radio"
                    name="application_id"
                    :value="15"
                    v-model="user.application_id"
                    data-e2e="contactform_application_id15"
                  />
                  Boxセキュアアクセスサービス for LGWAN
                </label>
              </div>
              <div class="form-check mb-1" v-if="isSansan">
                <label class="form-check-label">
                  <input
                    id="application_id16"
                    :class="'form-check-input h-75' + (errors && errors.application_id ? ' is-invalid ' : '')"
                    type="radio"
                    name="application_id"
                    :value="16"
                    v-model="user.application_id"
                    data-e2e="contactform_application_id16"
                  />
                  Sansan セキュアアクセスサービス for LGWAN
                </label>
              </div>
              <div class="form-check mb-1" v-if="isAIOCR">
                <label class="form-check-label">
                  <input
                    id="application_id17"
                    :class="'form-check-input h-75' + (errors && errors.application_id ? ' is-invalid ' : '')"
                    type="radio"
                    name="application_id"
                    :value="17"
                    v-model="user.application_id"
                    data-e2e="contactform_application_id17"
                  />
                  AI-OCR for LGWAN
                </label>
              </div>
            </div>
            <div class="text-danger" v-if="errors && errors.application_id">
              <span>{{ errors.application_id }}</span>
            </div>
          </div>

          <!-- 会社名 -->
          <div class="form-group">
            <label for="company_kanji">会社名 <span class="badge badge-danger ml-1 py-1">必須</span></label>

            <input
              type="text"
              :class="'form-control h-75' + (errors && errors.company_kanji ? ' is-invalid' : '')"
              id="company_kanji"
              v-model="user.company_kanji"
              data-e2e="contactform_company_kanji"
            />
            <div class="invalid-feedback">{{ errors.company_kanji }}</div>
          </div>

          <!-- 部署名 -->
          <div class="form-group">
            <label for="department_kanji">部署名</label>
            <input
              type="text"
              :class="'form-control h-75' + (errors && errors.department_kanji ? ' is-invalid' : '')"
              id="department_kanji"
              v-model="user.department_kanji"
              data-e2e="contactform_department_kanji"
            />
            <div class="invalid-feedback">{{ errors.department_kanji }}</div>
          </div>

          <!-- メールアドレス -->
          <div class="form-group">
            <label for="email">メールアドレス <span class="badge badge-danger ml-1 py-1">必須</span></label>
            <input
              type="email"
              :class="'form-control h-75' + (errors && errors.email ? ' is-invalid' : '')"
              id="email"
              placeholder="例) sample@example.com"
              v-model="user.email"
              data-e2e="contactform_email"
            />
            <div class="invalid-feedback">{{ errors.email }}</div>
          </div>

          <!-- 氏名 -->
          <div class="form-group">
            <label for="name">氏名 <span class="badge badge-danger ml-1 py-1">必須</span></label>
            <input
              type="name"
              :class="'form-control h-75' + (errors && errors.name ? ' is-invalid' : '')"
              id="name"
              placeholder="例) 山田 太郎"
              v-model="user.name"
              data-e2e="contactform_name"
            />
            <div class="invalid-feedback">{{ errors.name }}</div>
          </div>

          <!-- 電話番号 -->
          <div class="form-group">
            <label for="phone_number">電話番号 <span class="badge badge-danger ml-1 py-1">必須</span></label>
            <input
              type="tel"
              :class="'form-control h-75' + (errors && errors.phone_number ? ' is-invalid' : '')"
              id="phone_number"
              placeholder="例) 00-0000-0000"
              v-model="user.phone_number"
              data-e2e="contactform_phone_number"
            />
            <div class="invalid-feedback">{{ errors.phone_number }}</div>
          </div>

          <!-- お問い合わせ内容 -->
          <div class="form-group">
            <label for="contact">お問い合わせ内容 <span class="badge badge-danger ml-1 py-1">必須</span></label>
            <textarea
              :class="'form-control h-75' + (errors && errors.contact ? ' is-invalid' : '')"
              id="contact"
              rows="10"
              placeholder="例) ○○について教えて欲しい。"
              v-model="user.contact"
              autocomplete="off"
              data-e2e="contactform_contact"
            >
            </textarea>
            <div class="invalid-feedback">{{ errors.contact }}</div>
          </div>

          <div class="d-flex justify-content-center">
            <router-link
              class="btn btn-outline-info mr-3 btn-sm btn-width"
              to="/question"
              role="button"
              data-e2e="back_button"
              >戻る</router-link
            >
            <button
              v-if="!isLoading"
              type="button"
              class="btn btn-info btn-sm"
              @click="submit()"
              data-e2e="contactform_submit"
            >
              送信する
            </button>
            <button v-show="isLoading" class="btn btn-info btn-sm" type="button" disabled>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              登録中…
            </button>
          </div>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
import { apiMixin } from '@/mixins/api.js';

export default {
  name: 'ContactForm',
  mixins: [apiMixin],
  data() {
    return {
      user: null,
      get_user: null,
      errors: null,
      isLoading: false,
      isCanario: false,
      isOWLai: false,
      isRDLGWAN: false,
      isBoxLGWAN: false,
      isSansan: false,
      isAIOCR: false,
    };
  },
  created() {
    this.getUser();
    this.initialization();
  },
  methods: {
    initialization() {
      // 突入モードの取得
      this.isTechnical = false;
      if (this.$route.query.Technical !== undefined) {
        this.isTechnical = true;
        this.user = {
          application_id: '',
          company_kanji: '',
          department_kanji: '',
          email: '',
          name: '',
          phone_number: '',
          contact: '',
        };
      } else {
        this.user = {
          company_kanji: '',
          department_kanji: '',
          email: '',
          name: '',
          phone_number: '',
          contact: '',
        };
      }

      this.errors = {
        application_id: '',
        company_kanji: '',
        department_kanji: '',
        email: '',
        name: '',
        phone_number: '',
        contact: '',
      };
    },

    // 自動入力用ユーザー情報を取得
    async getUser() {
      // API共通処理（apiMixin）のGET用メソッドを実行
      this.get_user = (await this.getApi(`users/${this.$store.getters['auth/id']}`)) || null;

      // 中段の場合
      if (this.isTechnical) {
        for (const i in this.get_user.contractApps) {
          if (this.get_user.contractApps[i] == 9) {
            this.isCanario = true;
          }
          if (this.get_user.contractApps[i] == 10) {
            this.isOWLai = true;
          }
          if (this.get_user.contractApps[i] == 14) {
            this.isRDLGWAN = true;
          }
          if (this.get_user.contractApps[i] == 15) {
            this.isBoxLGWAN = true;
          }
          if (this.get_user.contractApps[i] == 16) {
            this.isSansan = true;
          }
          if (this.get_user.contractApps[i] == 17) {
            this.isAIOCR = true;
          }
        }

        // どちらもない場合
        if (this.isCanario === false && this.isOWLai === false && this.isRDLGWAN === false && this.isBoxLGWAN === false && this.isSansan === false && this.isAIOCR === false) {
          this.$router.push({ path: '/403' });
        }
      }

      // 自動入力する項目
      this.user.company_kanji = this.get_user.company_kanji;
      this.user.department_kanji = this.get_user.department_kanji;
      this.user.email = this.get_user.email;
    },

    // 登録
    async submit() {
      // バリデーション
      await this.postUser();
    },
    // ユーザー情報を登録する
    async postUser() {
      this.errors = {
        application_id: '',
        company_kanji: '',
        department_kanji: '',
        email: '',
        name: '',
        phone_number: '',
        contact: '',
      };
      let result = true;
      this.isLoading = true;
      // API共通処理（apiMixin）のPOST用メソッドを実行
      const response = (await this.postApi('contactSendMail', this.user)) || { result: false };
      this.isLoading = false;

      if (response.result) {
        this.$toasted.success('お問い合わせ受け付けました。');
        setTimeout(this.$router.push({ path: '/question' }), 3000);
      } else if (response.message) {
        result = false;
        for (var key in response.errors) {
          // エラーによってそれぞれのメッセージを設定させる
          switch (key) {
            case 'application_id':
              this.errors.application_id = 'お問い合わせサービスを選択してください。';
              break;
            case 'company_kanji':
              if (this.user.company_kanji.length === 0) {
                this.errors.company_kanji = '会社名を入力してください。';
                break;
              }
              this.errors.company_kanji = '会社名は100文字以内で入力してください。';
              break;
            case 'department_kanji':
              this.errors.department_kanji = '部署名は100文字以内で入力してください。';
              break;
            case 'email':
              if (this.user.email.length === 0) {
                this.errors.email = 'メールアドレスを入力してください。';
                break;
              } else if (this.user.email.length > 254) {
                this.errors.email = 'メールアドレスは254文字以内で入力してください。';
                break;
              }
              this.errors.email = 'メールアドレスが正しいか確認してください。';
              break;
            case 'name':
              if (this.user.name.length === 0) {
                this.errors.name = '氏名を入力してください。';
                break;
              }
              this.errors.name = '氏名は255文字以内で入力してください。';
              break;
            case 'phone_number':
              if (this.user.phone_number.length === 0) {
                this.errors.phone_number = '電話番号を入力してください。';
                break;
              }
              this.errors.phone_number = '電話番号は15文字以内で入力してください。';
              break;
            case 'contact':
              if (this.user.contact.length === 0) {
                this.errors.contact = 'お問い合わせ内容を入力してください。';
                break;
              }
              this.errors.contact = 'お問い合わせは1000文字以内で入力してください。';
              break;
          }

          this.errors.key = response.errors[key];
        }
        this.$toasted.error('入力内容に誤りがあります');
      }
      return result;
    },
  },
};
</script>

<style scoped>
.btn-width {
  width: 96px;
}

.badge {
  position: relative;
  top: -3px;
  font-size: 50%;
  border-radius: 0.25rem;
}
</style>
